body {
    overflow-x: hidden;
}

.App {
}

#errors {
    color: #d32f2f;
}

#errors ul {
    margin: 0;
    margin-bottom: 1em;
}

#errors p {
    margin: 0l;
}

table.table {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}

table.table td {
    border-right: 1px solid black;
    padding: 5px;
}

table.table thead {
    border-bottom: 2px solid black;
}
